<template>
  <div class="footer">
    <b-container class="footerContainer" fluid>

      <b-row>
        <b-col style="margin-bottom: 2rem;">
          <h3 style="margin-bottom: 0;">ITMR Developments</h3>
          <p>{{$t('general.slogan')}}</p>
        </b-col>
      </b-row>

      <b-row  style="margin-bottom: 2rem;">
        <b-col v-if="false">
          <p>
            <b>Deine ausgewählte Schule:</b><br>
            Du hast keine Schule ausgewählt <br>
            <router-link to="/school">Schule wählen</router-link>
          </p>
        </b-col>

        <b-col>
          <p>
            <b>{{$t('general.contact')}}</b><br>
            <a href="mailto:info@itmr-dev.de">{{$t('general.email')}}</a> <br>
            <a href="tel:+497153341213">{{$t('general.phone')}}</a> <br>
            <a href="https://discord.gg/maeNcKZ">{{$t('general.discord')}}</a> <br>
          </p>
        </b-col>

        <b-col>
          <a href="http://status.itmr-dev.de/" target="_blank" rel="noopener noreferrer">{{$t('general.systemstatus')}}</a> <br>
          <a href="https://github.com/itmr-dev/" target="_blank" rel="noopener noreferrer">{{$t('general.github')}}</a> <br>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p>
            ITMR Developments © {{new Date().getFullYear()}}
            <span> | </span>
            <router-link :to="`/${$i18n.locale}/imprint`">{{$t('general.imprint')}}</router-link>
            <span> | </span>
            <a href="#">{{$t('general.privacy')}}</a>
          </p>
        </b-col>
      </b-row>



    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    computed: {
    }
  }
</script>

<style scoped>

p {
  font-size: 1rem;
  color: white;
  hyphens: unset;
}

.modalText {
  color: black;
}

a {
  color: #4b93ff;
}

.footer {
  background-color: #444a50;
  color: white;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  margin: 0;
  padding: 2rem 5rem 2rem 5rem;
}

.footerContainer {
  height: 100%;
}

</style>