import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router.js'
import VueI18n from 'vue-i18n';
import languages from './lang/lang';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import store from './store/store'

Vue.config.productionTip = false

gsap.registerPlugin(ScrollTrigger);

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  }
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: languages,
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
