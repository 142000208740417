import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'
import Home from '../views/Home.vue'
import NavigationBar from '../components/NavigationBar.vue'
import Footer from '../components/Footer.vue'

import { i18n } from "../main.js";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: `/${navigator.language.split('-')[0]}`,
  },
  {
    path: "/:lang",
    component: App,
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!["en", "de"].includes(lang)) return next(`/en${to.path}`);
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      return next();
    },
    children: [
      {
        path: '',
        name: 'Home',
        components: {
          header: NavigationBar,
          default: Home,
          footer: Footer,
        }
      },
      {
        path: 'imprint',
        name: 'Imprint',
        components: {
          header: NavigationBar,
          default: () => import(/* webpackChunkName: "about" */ '../views/Imprint.vue'),
          footer: Footer,
        }
      },
      {
        path: 'contact',
        name: 'Contact',
        components: {
          header: NavigationBar,
          default: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
          footer: Footer,
        }
      },
      {
        path: 'Privacy',
        name: 'Privacy',
        components: {
          header: NavigationBar,
          default: () => import(/* webpackChunkName: "about" */ '../views/legal/Privacy.vue'),
          footer: Footer,
        }
      },
      {
        path: '404',
        name: '404',
        components: {
          header: NavigationBar,
          default: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
          footer: Footer,
        }
      },
      {
        path: 'cloud',
        name: 'Cloud',
        components: {
          header: NavigationBar,
          default: () => import(/* webpackChunkName: "about" */ '../views/Cloud.vue'),
          footer: Footer,
        }
      },
    ]
  },
  {
    path: "*",
    beforeEnter(to, from, next) {
      console.log(to);
      next();
      return next(`/${navigator.language.split('-')[0]}/404`);
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'route-active',
  linkExactActiveClass: 'route-exact-active',
  base: process.env.BASE_URL,
  routes
})

export default router
