<template>
  <b-container class="wrapper">
    <h2 id="projects" class="title">{{$t('general.projects')}}</h2>
    <p class="desc">{{$t('projects.desc')}}</p>
    <b-row>
      <b-col class="p">
        <b-card
          title="FG-Wildwarner"
          img-src="https://wildhueter-reflektor.de/wp-content/uploads/2020/04/IMG_8912_edited3-min-scaled.jpg"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
        >
          <b-card-text>
            
          </b-card-text>

          <b-button target="_blank" href="https://wildhueter-reflektor.de/" variant="primary">{{$t('general.openwebsite')}}</b-button>
        </b-card>
      </b-col>
      <b-col class="p">
        <b-card
          title="Marc Rufeis"
          img-src="../assets/imgs/marcrufeisde.png"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
        >
          <b-card-text>
            
          </b-card-text>

          <b-button target="_blank" href="https://www.marcrufeis.de/" variant="primary">{{$t('general.openwebsite')}}</b-button>
        </b-card>
      </b-col>
      <b-col class="p">
        <b-card
          title="silkframe media"
          img-src="../assets/imgs/silkframemediade.png"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
        >
          <b-card-text>
            
          </b-card-text>

          <b-button target="_blank" href="https://silkframe-media.de/" variant="primary">{{$t('general.openwebsite')}}</b-button>
        </b-card>
      </b-col>
      <b-col class="p">
        <b-card
          title="craftycram.net"
          img-src="../assets/imgs/craftycramnet.png"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
        >
          <b-card-text>
            
          </b-card-text>

          <b-button target="_blank" href="https://craftycram.net/" variant="primary">{{$t('general.openwebsite')}}</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {

}
</script>

<style scoped>

.wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title {
  text-align: center;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.desc {
  margin-bottom: 2rem;
  text-align: center;
}
</style>