<template>
  <div class="main-area center-text">
    <div class="display-table">
      <div class="display-table-cell">
        <h1 class="title font-white"><b>{{$t('general.slogan')}}</b></h1>
        <h4 class="desc font-white">{{$t('general.subslogan')}}</h4>
        <p class="desc font-white">
          {{$t('general.invaderSlogan')}} <a href="/invaders">SpaceInvaders-Game</a>.
        </p>
        <ul class="social-btn font-white">
          <li><a href="">{{$t('hero.ourprojects')}} <b-icon icon="chevron-down"/></a></li>
        </ul><!-- social-btn -->
      </div><!-- display-table -->
    </div><!-- display-table-cell -->
  </div><!-- main-area -->
</template>

<script>
export default {

}
</script>

<style>
/* ---------------------------------
3. MAIN SECTION
--------------------------------- */


.main-area{ position: relative; height: 100vh; padding: 0 20px; background-size: cover; color: #fff; z-index:1; background-image:url(../assets/bg2.jpg)}

.main-area:after{ content:''; position: absolute; top: 0; bottom: 0;left: 0; right: 0;  z-index: -1}

.main-area .desc{ margin: 20px auto; max-width: 800px; margin-bottom: 0px;}

.main-area .desc-btn{ width: 100%; left: 50%; margin-bottom: 20px}

.main-area .desc-btn > span{ margin: 0 2px; padding-bottom: 7px; position: relative; overflow: hidden}

.main-area .desc-btn > span:after{ content:''; position: absolute; bottom: 0; left: 0; right: 0;
    transition: all .2s; height: 2px;}

.main-area .desc-btn > span > a:after{content:''; position: absolute; bottom: 0; left: 0; right: 0;
    transition: all .2s; height: 2px; background: #02547f}

.main-area .desc-btn > span > a:hover:after{ transform: translateX(100%)}

.main-area .desc-btn > span > a:hover:after{ transform: translateX(100%)}

.main-area .notify-btn{ padding: 13px 35px; border-radius: 50px; border: 2px solid #02547f;
    color: #fff; background: #02547f; cursor: pointer;}

.main-area .notify-btn:hover{ background: none}

.main-area .social-btn{ position: absolute; bottom: 40px; width: 100%; left: 50%; transform: translateX(-50%)}

.main-area .social-btn > li{ margin: 0 2px; padding-bottom: 7px; position: relative; overflow: hidden;}

.main-area .social-btn > li:after{ content:''; position: absolute; bottom: 0; left: 0; right: 0;
    transition: all .2s; height: 2px;}

/*.main-area .social-btn > li > a{ margin: 0 10px; padding-bottom: 7px; position: relative; overflow: hidden}*/
.main-area .social-btn > li > a{cursor: pointer;}

.main-area .social-btn > li > a:after{content:''; position: absolute; bottom: 0; left: 0; right: 0;
    transition: all .2s; height: 2px; background: #02547f}


.main-area .social-btn > li > a:hover:after{ transform: translateX(100%)}



/*responsive*/



/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {

}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {



}


/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {

    /* ---------------------------------
    1. PRIMARY STYLES
    --------------------------------- */

    p{ line-height: 1.4; }

    h1{ font-size: 2.8em; line-height: 1; }
    h2{ font-size: 2.2em; line-height: 1.1; }
    h3{ font-size: 1.8em; }



}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {

    /* ---------------------------------
    1. PRIMARY STYLES
    --------------------------------- */

    body{ font-size: 12px; }

}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {


}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {

}
</style>