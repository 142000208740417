<template>
  <b-row :class="{ navbar: true, navbarFilled: filledNavBar}" :style="cssStyles">
    <b-col cols="1">
      <img src="@/assets/logo.svg" class="navbar-logo" alt="" @click="$router.push('/')">
    </b-col>

    <b-col cols="1">
      <p class="navbar-title">ITMR Developments</p>
    </b-col>
    
    <b-col cols="4"/>

    <b-col>
      <router-link :to="`/${$i18n.locale}`">{{$t('routes.home')}}</router-link>
    </b-col>
    
    <b-col>
      <router-link to="#projects">{{$t('routes.projects')}}</router-link>
    </b-col>

    <b-col>
      <router-link to="#">{{$t('routes.aboutus')}}</router-link>
    </b-col>

    <b-col>
      <router-link :to="`/${$i18n.locale}/contact`">{{$t('routes.contact')}}</router-link>
    </b-col>

  </b-row>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      activeNavColor: 'white',
    };
  },
  computed: {
    filledNavBar() {
      return !this.$store.state.scrolledTop;
    },
    cssStyles() {
      return {
        '--navColor': this.activeNavColor,
      };
    },
  },
  watch: {
    filledNavBar() {
      this.activeNavColor = this.filledNavBar ? 'black' : 'white';
    }
  }, 
}
</script>

<style scoped>

.navbar {
  height: 4rem;
  line-height: 4rem;
  width: 100vw;
  position: fixed;
  z-index: 10;
  transition: 0.5s;
  color: var(--navColor);
}

.navbarFilled {
  transition: 0.5s;
  background-color: white;
}

.navbar-logo {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.navbar-title {
  color: inherit;
  font-weight: 500;
}

.route-active {
  color: var(--navColor);
}

.routes{
  position: absolute;
  top: 35px;
  right: 60px;
}

/*
a.routes:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.route-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
a.routes:hover:before {
  visibility: visible;
  width: 100%;
}
*/
</style>