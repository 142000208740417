<template>
  <div class="home">
    <Hero/>
    <Projects/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Projects from '@/components/Projects.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    Projects,
  }
}
</script>

<style scoped>
</style>